<template lang="pug">
div#ido-pei
  div.film-title.bg.red.mod
    div.f-t-box.flex.txt.red
     div.ico.margin._0_5: img(src="@/assets/svg/b.svg")
     div.bg.white.center {{article.at_title}}
  videoBox(:src="video.src")/
  div.dialogue.mod.small
    div(v-html="article.at_con")
  div.record.mod.center(v-if="article.is_dubbing")
    uploader(:after-read="afterRead" accept="audio/ogg,audio/mp3,audio/wav,audio/m4a,audio/flac")
      img.bg.red.padding._5(src="@/assets/svg/mike.svg")
</template>
<script>
import videoBox from '@/components/modal/video'
import 'vant/lib/uploader/index.css'
import { Uploader } from 'vant'
export default {
  components: {videoBox, uploader: Uploader},
  data(){ return {
    video: {src: ''},
    article: {},
  }},
  methods: {
    afterRead(file){
      const formData = new FormData()
      formData.append('file', file.file)
      formData.append('d_id', this.article.d_id)
      this.$req.uploader('wlpAudio', formData, ()=>{

      })
    }
  },
  mounted(){
    let d_id = this.$route.params.id
    this.$req.wlpId({d_id}, (res)=>{
      let {video_url, at_con, at_title, is_dubbing} = res
      this.video.src = video_url
      this.article = {at_con, at_title, is_dubbing, d_id}
    })
  },
}
</script>
<style lang="less">
@import "~@/assets/less/func.less";
#ido-pei{
  .f-t-box{
    @bcolor: rgba(255,255,255,.6);
    @clip: 1rem / @fontsize * 6;
    @bgw:  1rem / @fontsize * 4;
	  clip-path: polygon(@clip 0, calc(100% - @clip) 0, 100% @clip, 100% calc(100% - @clip), calc(100% - @clip) 100%, 6px 100%, 0 calc(100% - @clip), 0 @clip);
    background: linear-gradient(-45deg, @bcolor @bgw, transparent 0) bottom right, linear-gradient(45deg, @bcolor  @bgw, transparent 0) bottom left, linear-gradient(135deg, @bcolor  @bgw, transparent 0) top left, linear-gradient(-135deg, @bcolor  @bgw, transparent 0) top right;
	  .__border(all, @bcolor);
    .__spacing(padding, 3);
    display: inline-flex;
    .ico{
      .__wh(16, 16);
    }
    .bg{
      .__spacing(padding, 3, 20);
      clip-path: polygon(0 0, calc(100% - @clip) 0, 100% @clip, 100% calc(100% - @clip), calc(100% - @clip) 100%, 0 100%);
    }
  }
  .dialogue{
    line-height: 1.8;
    .__border(bottom, #ddd);
  }
  .record img{
    .__wh(28, 28);
    border-radius: 50%;
  }
}
</style>